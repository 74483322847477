import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Field, Form, Formik} from 'formik'
import {v4 as uuidv4} from 'uuid'
import * as Yup from 'yup'
import toBase64 from '../../utils/to-base64'
import {Dispatch, SetStateAction} from 'react'
import {useRouter} from 'next/router'
import {getUrlFromRichtext} from '../../utils/url-from-richtext'
import {useTranslations} from 'next-intl'
import classNames from 'classnames'
import {countryLocaleMap, localeCountryMap} from '../../constants'
import React from 'react'
import {MatchboxVacancyEnriched} from '../../types/api-types'
import {trpc} from '../../utils/trpc'
import {TaxonomyTerm} from '../../types/content-types'

declare const window: any

type ApplicationFields = {
  firstName: string
  lastName: string
  email: string
  phone: string
  country: string
  zipCode: string
  cv: File | null
  privacyAccepted: boolean
  declarationIntent: boolean
  vacancyId?: string
}

const phoneRegExp = /^[+]??[0-9 -]{10,21}$/

type FormStatus = {
  success: boolean
  message?: string
}

type Props = {
  errorRequired: string
  errorPhoneFormat: string
  errorEmailFormat: string
  errorFileSize: string
  errorFileExtension: string
  errorPrivacyCheckboxRequired: string
  labelFirstName: string
  placeholderFirstName?: string
  labelLastName: string
  placeholderLastName?: string
  labelEmail: string
  placeholderEmail?: string
  labelPhone: string
  placeholderPhone?: string
  labelCountry: string
  labelZipCode: string
  placeholderZipCode?: string
  labelSubmitButton: string
  disclaimerUpload: string
  labelPrivacyCheckbox?: string | null
  labelIntent?: string | null
  labelCvUpload: string
  privacyConsent?: string
  setFormStatus?: Dispatch<SetStateAction<FormStatus | null>>
  isOpenApplication?: boolean
  countries?: TaxonomyTerm[]
  vacancy?: MatchboxVacancyEnriched
}

const ApplicationForm = ({
  errorRequired,
  errorPhoneFormat,
  errorEmailFormat,
  errorFileSize,
  errorFileExtension,
  labelFirstName,
  placeholderFirstName,
  labelLastName,
  placeholderLastName,
  labelEmail,
  placeholderEmail,
  labelPhone,
  placeholderPhone,
  labelCountry,
  labelZipCode,
  placeholderZipCode,
  labelSubmitButton,
  disclaimerUpload,
  labelPrivacyCheckbox,
  labelCvUpload,
  countries,
  labelIntent,
  privacyConsent,
  setFormStatus,
  isOpenApplication = false,
  vacancy,
}: Props) => {
  const router = useRouter()
  const t = useTranslations()
  const uniqueId = uuidv4()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const {mutate: submitApplicationForm} =
    trpc.submitApplicationForm.useMutation({
      onSettled(res) {
        if (res?.ok) {
          setIsSubmitting(false)
          if (isOpenApplication) {
            setFormStatus?.({success: true})
          } else {
            window.dataLayer.push({ecommerce: null})
            window.dataLayer.push({
              event: 'purchase',
              ecommerce: {
                transaction_id: uniqueId,
                value: 0,
                currency: 'EUR',
                items: [
                  {
                    item_id: vacancy?.id,
                    item_name: vacancy?.document.extraProperties.title, //job title
                    affiliation: vacancy?.document.extraProperties.officeName, // office name
                    price: 0,
                    quantity: 1,
                    item_brand:
                      vacancy?.document.extraProperties.companyContentMapped
                        ?.company?.name, // customer name (NAAM ORGANISATIE)
                    item_category:
                      vacancy?.document.extraProperties.domainMapped, //job segment / domain (Logistiek/Industrie/Office)
                    item_category2:
                      vacancy?.document.extraProperties.functionLevelMapped, //function level
                    item_category3: vacancy?.document.extraProperties.fullTime
                      ? 'Fulltime'
                      : 'Parttime', //fulltime or parttime
                    location_id: vacancy?.document.extraProperties.location, //job location / city
                    ...(vacancy?.document.extraProperties.jobPoster && {
                      job_source: 'Job Poster',
                    }),
                  },
                ],
              },
            })
            router.push(
              `${getUrlFromRichtext(
                t.raw('jobs_application_successpage') ?? '',
              )}`,
            )
          }
        } else {
          setIsSubmitting(false)
          if (isOpenApplication) {
            setFormStatus?.({success: false})
          } else {
            router.push(
              `${getUrlFromRichtext(
                t.raw('jobs_application_errorpage') ?? '',
              )}`,
            )
          }
        }
      },
    })
  // split on _ because we don't need the _jobs postfix here
  const country =
    localeCountryMap[
      (router.locale?.toLowerCase() as
        | keyof typeof localeCountryMap
        | undefined) ?? 'nl-be'
    ].split('_')[0]
  const initialValues: ApplicationFields = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: country,
    zipCode: '',
    cv: null,
    privacyAccepted: false,
    declarationIntent: false,
    vacancyId: '',
  }

  return (
    <Formik
      validateOnBlur
      onSubmit={async values => {
        setIsSubmitting(true)
        let base64File = ''
        try {
          base64File = (await toBase64(values.cv!)) as string
        } catch (err) {
          setFormStatus?.({success: false})
          return
        }
        let encoded = base64File.toString().replace(/^data:(.*,)?/, '')
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4))
        }

        const utm_source = sessionStorage.getItem('utm_source') as string
        const utm_medium = sessionStorage.getItem('utm_medium') as string

        let dataToSend = {
          vacancyId: vacancy?.document.extraProperties.vacancyId.toString(),
          firstName: values.firstName,
          lastName: values.lastName,
          namePrefix: values.firstName.charAt(0),
          telephone: values.phone,
          email: values.email,
          country:
            countryLocaleMap[values.country as keyof typeof countryLocaleMap],
          zipCode: values.zipCode,
          fileName: values.cv!.name,
          fileContent: encoded,
          fileType: values.cv!.type,
          applicationDate: new Date().toISOString(),
          source: utm_source ? JSON.parse(utm_source) : '',
          medium: utm_medium ? JSON.parse(utm_medium) : '',
          channel: 'jobs',
        }

        sessionStorage.removeItem('utm_source')
        sessionStorage.removeItem('utm_medium')

        if (isOpenApplication) {
          delete dataToSend.vacancyId
        }
        submitApplicationForm(dataToSend)
      }}
      initialValues={initialValues}
      validationSchema={Yup.object({
        firstName: Yup.string().required(errorRequired),
        lastName: Yup.string().required(errorRequired),
        country: Yup.string().required(errorRequired),
        zipCode: Yup.string().required(errorRequired),
        phone: Yup.string()
          .matches(phoneRegExp, errorPhoneFormat)
          .required(errorRequired),
        email: Yup.string().email(errorEmailFormat).required(errorRequired),
        cv: Yup.mixed()
          .required(errorRequired)
          .test('fileSize', errorFileSize, value => {
            return value?.size <= 5000000
          })
          .test('type', errorFileExtension, value => {
            return (
              value &&
              [
                'application/pdf',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              ].includes(value.type)
            )
          }),
        ...(!isOpenApplication && {
          vacancyId: Yup.string(),
        }),
        ...(!isOpenApplication &&
          router.locale?.includes('be') && {
            declarationIntent: Yup.bool()
              .oneOf([true], errorRequired)
              .required(errorRequired),
          }),
        privacyAccepted: Yup.bool()
          .oneOf([true], errorRequired)
          .required(errorRequired),
      })}
    >
      {({errors, touched, handleChange, setFieldValue, values}) => (
        <Form className="c-open-application__form-group-wrapper d-flex flex-column">
          <Row className="c-open-application__form-group">
            <Col span={6}>
              <label
                className="d-flex flex-column mb-0 form-label"
                htmlFor="firtsName"
                id="firtsNameLabel"
              >
                {labelFirstName}
                <Field
                  name="firstName"
                  className="form-control"
                  placeholder={placeholderFirstName}
                  id="firtsName"
                  aria-labelledby="firtsNameLabel"
                />
              </label>
              {errors.firstName && touched.firstName ? (
                <span className="error-message">{errors.firstName}</span>
              ) : null}
            </Col>
            <Col span={6}>
              <label
                className="d-flex flex-column mb-0 form-label"
                htmlFor="lastName"
                id="lastNameLabel"
              >
                {labelLastName}
                <Field
                  name="lastName"
                  className="form-control"
                  placeholder={placeholderLastName}
                  id="lastName"
                  aria-labelledby="lastNameLabel"
                />
              </label>
              {errors.lastName && touched.lastName ? (
                <span className="error-message">{errors.lastName}</span>
              ) : null}
            </Col>
          </Row>
          <Row className="c-open-application__form-group">
            <Col span={6}>
              <label
                className="d-flex flex-column mb-0 form-label"
                htmlFor="email"
                id="emailLabel"
              >
                {labelEmail}
                <Field
                  name="email"
                  className="form-control"
                  placeholder={placeholderEmail}
                  id="email"
                  aria-labelledby="emailLabel"
                />
              </label>
              {errors.email && touched.email ? (
                <span className="error-message">{errors.email}</span>
              ) : null}
            </Col>
            <Col span={6}>
              <label
                className="d-flex flex-column mb-0 form-label"
                htmlFor="phone"
                id="phoneLabel"
              >
                {labelPhone}
                <Field
                  name="phone"
                  className="form-control"
                  placeholder={placeholderPhone}
                  id="phone"
                  aria-labelledby="phoneLabel"
                />
              </label>
              {errors.phone && touched.phone ? (
                <span className="error-message">{errors.phone}</span>
              ) : null}
            </Col>
          </Row>
          <Row className="c-open-application__form-group">
            <Col span={6}>
              <label
                className="d-flex flex-column mb-0 form-label"
                htmlFor="country"
                id="countryLabel"
              >
                {labelCountry}
                <Field
                  as="select"
                  name="country"
                  className="form-control"
                  id="country"
                  aria-labelledby="countryLabel"
                >
                  {countries?.map(country => (
                    <option value={country.field_code} key={country.field_code}>
                      {country.name}
                    </option>
                  ))}
                </Field>
              </label>
              {errors.country && touched.country ? (
                <span className="error-message">{errors.country}</span>
              ) : null}
            </Col>
            <Col span={6}>
              <label
                className="d-flex flex-column mb-0 form-label"
                htmlFor="zipcode"
                id="zipcodeLabel"
              >
                {labelZipCode}
                <Field
                  name="zipCode"
                  className="form-control"
                  placeholder={placeholderZipCode}
                  id="zipcode"
                  aria-labelledby="zipcodeLabel"
                />
              </label>
              {errors.zipCode && touched.zipCode ? (
                <span className="error-message">{errors.zipCode}</span>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col className="flex-column">
              <div>
                <div className="d-flex">
                  <label
                    htmlFor="cv_upload"
                    id="cvUploadLabel"
                    className="c-open-application__upload-btn text-tuna-gray-60 py-2 w-100 text-center mb-1 font-weight-bold d-flex align-items-center justify-content-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      className="mr-2"
                    >
                      <path
                        d="M2 16.0747C1.45 16.0747 0.979333 15.879 0.588 15.4877C0.196 15.0957 0 14.6247 0 14.0747V11.0747H2V14.0747H14V11.0747H16V14.0747C16 14.6247 15.8043 15.0957 15.413 15.4877C15.021 15.879 14.55 16.0747 14 16.0747H2ZM7 12.0747V3.92471L4.4 6.52471L3 5.07471L8 0.074707L13 5.07471L11.6 6.52471L9 3.92471V12.0747H7Z"
                        fill="#898E9A"
                      />
                    </svg>
                    {labelCvUpload}
                  </label>
                  <input
                    id="cv_upload"
                    aria-labelledby="cvUploadLabel"
                    type="file"
                    className="opacity-0 w-0"
                    name="cv"
                    onChange={e => {
                      if (e.currentTarget.files?.[0]) {
                        setFieldValue('cv', e.currentTarget.files?.[0])
                      }
                    }}
                  />
                </div>
                {values.cv ? <p>{values.cv.name}</p> : null}
                {errors.cv && touched.cv ? (
                  <span className="error-message">{errors.cv}</span>
                ) : null}
                <span className="d-block text-sm text-tuna-gray-60 font-lighter">
                  {disclaimerUpload}
                </span>
              </div>
              {privacyConsent ? (
                <div
                  className="text-tuna-gray-80 c-open-application__consent-text mt-3"
                  dangerouslySetInnerHTML={{
                    __html: privacyConsent,
                  }}
                />
              ) : null}
              <div>
                <label
                  className="d-flex mt-3 mb-0"
                  htmlFor="privacyAccepted"
                  id="privacyAcceptedLabel"
                >
                  <input
                    className="no-translate"
                    type="checkbox"
                    name="privacyAccepted"
                    onChange={handleChange}
                    id="privacyAccepted"
                    aria-labelledby="privacyAcceptedLabel"
                  />
                  {labelPrivacyCheckbox ? (
                    <div
                      className="ml-2 children-no-margin text-tuna-gray-80 c-open-application__privacy-text"
                      dangerouslySetInnerHTML={{
                        __html: labelPrivacyCheckbox,
                      }}
                    />
                  ) : null}
                </label>
                {errors.privacyAccepted && touched.privacyAccepted ? (
                  <span className="error-message">
                    {errors.privacyAccepted}
                  </span>
                ) : null}
              </div>
              {!isOpenApplication && router.locale?.includes('be') ? (
                <div>
                  <label
                    className="d-flex mt-3 mb-0"
                    htmlFor="declarationIntent"
                    id="declarationIntentLabel"
                  >
                    <input
                      className="no-translate"
                      type="checkbox"
                      name="declarationIntent"
                      onChange={handleChange}
                      id="declarationIntent"
                      aria-labelledby="declarationIntentLabel"
                    />
                    {labelIntent ? (
                      <div
                        className="ml-2 children-no-margin text-tuna-gray-80 c-open-application__privacy-text"
                        dangerouslySetInnerHTML={{
                          __html: labelIntent,
                        }}
                      />
                    ) : null}
                  </label>
                  {errors.declarationIntent && touched.declarationIntent ? (
                    <span className="error-message">
                      {errors.declarationIntent}
                    </span>
                  ) : null}
                </div>
              ) : null}
              <button
                type="submit"
                disabled={isSubmitting ? true : false}
                className={classNames(
                  'btn mt-4 py-2 px-4 d-flex align-items-center justify-content-center btn--orange c-open-application__submit-btn font-weight-light align-items-center',
                  {'submitting-loader': isSubmitting},
                )}
              >
                {labelSubmitButton}
              </button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default ApplicationForm
